const FooterLogo = () => {
  return (
    <>
      <img
        className="max-w-[32px]"
        src="https://img.icons8.com/color/48/000000/facebook-new.png"
        alt="Facebook"
      />

      <img
        className="max-w-[32px]"
        src="https://img.icons8.com/color/48/000000/tiktok.png"
        alt="TikTok"
      />

      <img
        className="max-w-[32px]"
        src="https://img.icons8.com/color/48/000000/youtube-play.png"
        alt="YouTube"
      />

      <img
        className="max-w-[32px]"
        src="https://img.icons8.com/color/48/000000/zalo.png"
        alt="Zalo"
      />

      <img
        className="max-w-[32px]"
        src="https://img.icons8.com/color/48/000000/linkedin.png"
        alt="LinkedIn"
      />
    </>
  );
};

export default FooterLogo;
