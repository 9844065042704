"use client";

import AdahubColors from "@/config/landingPage/AdahubColors";
import { Card, CardHeader, CardBody, Image } from "@nextui-org/react";
import ContactForm from "../ContactForm";
import BackgroundImage from "./background.png";

export const HeroContactUs = () => {
  return (
    <div className="bg-adahubContactFormBg size-full min-h-[680px] rounded-2xl p-8">
      <Card className="">
        <div className="relative w-full overflow-hidden min-h-[180px] mt-3">
          <Image
            placeholder="blur"
            src={BackgroundImage}
            alt="Background"
            className="-z-10 object-fit-cover w-full h-full position-absolute"
          />
          <div className="relative z-10">
            <CardHeader className="pb-0 h-1 text-[adahubPrimary] pt-2 px-4 flex-col items-center">
              <h1 style={{ color: AdahubColors.primary }}>LIÊN HỆ TƯ VẤN</h1>
            </CardHeader>
            <CardBody className="overflow-visible py-2">
              <ContactForm />
            </CardBody>
          </div>
        </div>
      </Card>
    </div>
  );
};

const ContactHero = () => {
  return (
    <div className="bg-adahubContactFormBg size-full md:min-h-[680px] rounded-2xl p-8">
      <h1 className="font-bold text-3xl text-adahubSecondary py-4">
        LIÊN HỆ TƯ VẤN
      </h1>
      <ContactForm />
    </div>
  );
};

export default ContactHero;
