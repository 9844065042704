"use client";

import AdahubHeader from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/AdahubHeader";
import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import HeroCRM from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/HeroCRM";
import SolutionsCRM from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/SolutionsCRM";
import UseCasesGrid from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/UseCasesGrid";
import WhyChooseCRM from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/WhyChooseCRM";
import Features from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Features";
import Customers from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Customers";
import PricingCRM from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/PricingCRM";
import Testimonial from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Testimonial";
import ContactUsForm from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContactUsForm";
import Banner4 from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Banner4";
import AppliedVideoDemo from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/AppliedVideoDemo";
import FeaturesCrm from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/FeaturesCrm";
import Banner5 from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Banner5";
import { useEffect, useState } from "react";
import ContactFormModal from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContactFormModal";
import FooterNew from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Footer-new";

const LandingPageCrmPage = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);

  const handleScroll = () => {
    const thresholdElement = document.getElementById("thresholdElement");
    if (!thresholdElement) {
      return;
    }
    const scrollThreshold = thresholdElement.offsetTop - window.innerHeight;

    if (window.scrollY >= scrollThreshold && !hasShownPopup) {
      setShowPopup(true);
      setHasShownPopup(true);
      window.removeEventListener("scroll", handleScroll);
    }
  };

  useEffect(() => {
    if (!showPopup) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showPopup]);

  return (
    <>
      <section id="contactHero" />
      <AdahubHeader logo="adahub" />
      <ContentContainer className="pt-[36px]">
        <div>
          <section>
            <HeroCRM />
          </section>
          <section id="solutions">
            <SolutionsCRM />
          </section>
        </div>
      </ContentContainer>
      <Banner4 />
      <ContentContainer>
        <AppliedVideoDemo />
      </ContentContainer>

      <ContentContainer>
        <FeaturesCrm />
      </ContentContainer>
      <Banner5 />

      <ContentContainer>
        <WhyChooseCRM />
      </ContentContainer>
      <div id="thresholdElement">
        {showPopup && (
          <ContactFormModal
            isOpen={showPopup}
            close={() => setShowPopup(false)}
          />
        )}
      </div>
      <UseCasesGrid type="crm" />
      <section id="product">
        <Features type="adahub" />
      </section>
      <Customers type="adahub" />
      <div className="bg-gradient-to-b from-adahubContactFormBg to-white mt-9">
        <section id="pricing">
          <ContentContainer>
            <PricingCRM
              heading={
                "CHỈ TỪ 50.000 VNĐ/THÁNG\nBẢNG GIÁ ĐA LỰA CHỌN - PHÙ HỢP MỌI QUY MÔ"
              }
            />
          </ContentContainer>
        </section>
      </div>
      <div className="mt-14">
        <Testimonial type="adahub" />
      </div>
      <ContactUsForm />
      <FooterNew logo="adahub" />
    </>
  );
};

export default LandingPageCrmPage;
