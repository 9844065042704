import LogoUcall from "./logoUcall.png";
import LogoAdahub from "./dFR12T4i4M 1.png";
import Image from "next/image";
import FooterLogo from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/FooterLogo";

interface FooterNewProps {
  hasContactForm?: boolean;
  logo?: "ucall" | "adahub";
}
const FooterNew = ({
  hasContactForm = true,
  logo = "ucall",
}: FooterNewProps) => {
  return (
    <footer
      className="bg-adahubSecondary flex flex-row items-center justify-center"
      style={{ color: "white" }}>
      <div
        className={`${hasContactForm ? "mt-[380px]" : "mt-[64px]"} justify-center flex-column flex max-w-[1200px] px-4 pb-10 md:pb-24`}>
        <div className="grid grid-cols-1 md:grid-cols-6 gap-5 ">
          <div className="col-span-1 md:col-span-1">
            {logo === "adahub" && <Image src={LogoAdahub} alt="adahub logo" />}
            {logo === "ucall" && (
              <Image
                className="w-[68px] h-[70px]"
                src={LogoUcall}
                alt="LogoUcall"
              />
            )}
          </div>
          <div className="col-span-1 md:col-span-3">
            <h1 className="text-2xl mb-3">CÔNG TY TNHH GIẢI PHÁP ETRON</h1>
            <p className="py-3">
              Trụ sở: Số nhà 66 Hẻm 89/36/2 phố Phan Kế Bính, Phường Cống Vị,
              Quận Ba Đình, Thành phố Hà Nội, Việt Nam
            </p>
            <p>Văn phòng: A24/D1 Khúc Thừa Dụ, Cầu Giấy, Hà Nội</p>
          </div>
          <div className="flex flex-col gap-3 col-span-1 md:col-span-2 ">
            <div className="w-fit flex flex-row gap-x-3.5 bg-adahubSecondary mb-3">
              <FooterLogo />
            </div>
            <div className="flex flex-col items-start pl-1 gap-2">
              {logo == "adahub" && (
                <>
                  <p>Website: adahub.vn</p>
                </>
              )}
              {logo == "ucall" && (
                <>
                  <p>Website: https://ucall.vn</p>
                </>
              )}

              {logo == "adahub" && (
                <>
                  <p>Email: service@adahub.vn</p>
                </>
              )}
              {logo == "ucall" && (
                <>
                  <p>Email: contact@ucall.asia</p>
                </>
              )}
              <p>Hotline: 0249 9999 468</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterNew;
