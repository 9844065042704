"use client";

import Logo from "./logo.svg";
import LogoUcall from "./logo_ucall.png";
import Link from "next/link";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Button,
} from "@nextui-org/react";
import Image from "next/image";
import {
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from "@nextui-org/navbar";
import useDisclosure from "@/hooks/shared/useDisclosure";
import { useScrollYPosition } from "react-use-scroll-position";
import { usePathname } from "next/navigation";
import { useRef } from "react";
import { useInView } from "framer-motion";
import {
  logHeaderClick,
  logOpenRegistration,
} from "@/utils/landingPage/logEvent";
import ContactFormModal from "../ContactFormModal";

const AdahubHeader = ({ logo = "adahub" }: { logo?: "adahub" | "ucall" }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const scrollY = useScrollYPosition();
  const { isOpen, toggle, close: closeMenu } = useDisclosure();
  const { open, isOpen: formModal, close } = useDisclosure();
  const pathname = usePathname();
  const menuItems = [
    { name: "Sản phẩm", url: "product" },
    { name: "Giải pháp", url: "solutions" },
    { name: "Bảng giá", url: "pricing" },
    { name: "Đánh giá", url: "testimonial" },
  ];

  const startScroll = scrollY > 30 || !isInView;

  const handleAdviseMe = () => {
    logOpenRegistration();
    open();
  };
  return (
    <Navbar
      ref={ref}
      position="static"
      shouldHideOnScroll={false}
      isMenuOpen={isOpen}
      onMenuOpenChange={toggle}
      className={`overflow-hidden transition-all duration-300 ${!isOpen ? "bg-transparent" : ""} ${scrollY > 50 ? "bg-white" : ""}`}
      style={{ position: "fixed" }}
      height={startScroll ? "3rem" : "4rem"}
      isBordered={isOpen || startScroll}
      isBlurred={false}>
      <div className="flex justify-between items-center w-full md:hidden">
        <NavbarBrand>
          <Link href={pathname}>
            {logo === "adahub" && <Image src={Logo} alt="adahub logo" />}
            {logo === "ucall" && (
              <Image
                placeholder="blur"
                className="max-w-28"
                src={LogoUcall}
                alt="ucall logo"
              />
            )}
          </Link>
        </NavbarBrand>
        <NavbarContent className="sm:hidden flex-grow-0">
          <NavbarMenuToggle aria-label={isOpen ? "Close menu" : "Open menu"} />
        </NavbarContent>
      </div>
      <NavbarBrand className="hidden md:block">
        <Link href={pathname}>
          {logo === "adahub" && <Image src={Logo} alt="adahub logo" />}
          {logo === "ucall" && (
            <Image
              placeholder="blur"
              className="max-w-28"
              src={LogoUcall}
              alt="ucall logo"
            />
          )}
        </Link>
      </NavbarBrand>
      <NavbarContent
        className="hidden sm:flex gap-4 flex-wrap"
        justify="center">
        <NavbarItem>
          <Button
            disableRipple
            href="#product"
            as={Link}
            className="p-0 bg-transparent data-[hover=true]:bg-transparent"
            radius="sm"
            variant="light">
            Sản phẩm
          </Button>
        </NavbarItem>
        <NavbarItem>
          <Button
            onClick={() => logHeaderClick("header_solution")}
            disableRipple
            href="#solutions"
            as={Link}
            className="p-0 bg-transparent data-[hover=true]:bg-transparent"
            radius="sm"
            variant="light">
            Giải pháp
          </Button>
        </NavbarItem>
        <NavbarItem>
          <Button
            disableRipple
            href="#pricing"
            onClick={() => logHeaderClick("header_pricing")}
            as={Link}
            className="p-0 bg-transparent data-[hover=true]:bg-transparent"
            radius="sm"
            variant="light">
            Bảng giá
          </Button>
        </NavbarItem>
        <NavbarItem>
          <Button
            disableRipple
            href="#testimonial"
            as={Link}
            className="p-0 bg-transparent data-[hover=true]:bg-transparent"
            radius="sm"
            variant="light">
            Đánh giá
          </Button>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent className="hidden sm:flex" justify="end">
        <NavbarItem>
          <div className="flex flex-row gap-x-2">
            <Button
              className="mr-2 border-adahubSecondary text-adahubSecondary bg-white"
              as={Link}
              size={startScroll ? "sm" : "md"}
              color="primary"
              href="https://app.adahub.vn"
              variant="bordered">
              Tạo tài khoản
            </Button>
            <Button
              // as={Link}
              onClick={handleAdviseMe}
              size={startScroll ? "sm" : "md"}
              color="primary"
              // href="#contact"
              variant="solid"
              className="bg-adahubSecondary">
              Tư vấn tôi
            </Button>
          </div>
        </NavbarItem>

        <NavbarMenu className="bg-white backdrop-blur-xl rounded-b-sm">
          <div className="flex-grow-1">
            {menuItems.map((item, index) => (
              <NavbarMenuItem key={`${item.name}-${index}`}>
                <Button
                  as={Link}
                  color="primary"
                  href={`#${item.url}`}
                  variant="ghost"
                  onClick={closeMenu}
                  size="lg"
                  className="w-full mb-2 border-adahubSecondary text-adahubSecondary">
                  {item.name}
                </Button>
              </NavbarMenuItem>
            ))}
          </div>

          <div className="flex flex-column flex-wrap gap-y-3 mb-16 text-white">
            <Button
              className="w-full bg-adahubSecondary text-white py-1"
              as={Link}
              color="primary"
              href="https://app.adahub.vn"
              size="sm"
              variant="shadow">
              Tạo tài khoản
            </Button>
            <Button
              // as={Link}
              color="default"
              className="w-full"
              onClick={handleAdviseMe}
              // href="#contact"
              variant="solid">
              Tư vấn tôi
            </Button>
          </div>
        </NavbarMenu>
      </NavbarContent>
      <ContactFormModal isOpen={formModal} open={open} close={close} />
    </Navbar>
  );
};

export default AdahubHeader;
